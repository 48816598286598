<template>
    <div class="mt-6">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        STAFF
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        DATE
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Action
                    </th>
                </tr>
            </thead>

            <tbody
                v-if="data.length >= 1"
                class="bg-white divide-y divide-gray-200 w-full"
            >
                <tr v-for="(transaction, i) in data" :key="i">
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <router-link
                                    :to="`/staffs/${transaction.staff_id}`"
                                    class="text-sm font-medium text-gray-900"
                                >
                                    {{ transaction.staff_name }}
                                    <p class="text-gray-500 text-xs">
                                        {{ transaction.branch }}
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            {{
                                transaction.created_at
                                    | moment('dddd, MMMM Do YYYY')
                            }}
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <button
                            v-if="
                                checkPermissionExist(
                                    user.data,
                                    'revise-worksheet'
                                )
                            "
                            @click="clearPendingWorksheet(transaction)"
                            class="text-red-500 text-sm underline"
                        >
                            {{ isLoading ? 'Clearing...' : 'Clear' }}
                        </button>
                    </td>
                </tr>

                <!-- More people... -->
            </tbody>

            <tbody v-else-if="Loading" class="flex justify-center w-full">
                <tr class="py-4 text-center" col="2">
                    <spinner :show="Loading" :content="`Loading `" />
                </tr>
            </tbody>
            <tbody v-else class="flex justify-center">
                <tr class="py-4 text-gray-500" col="2">
                    No Pending Worksheet found
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import checkPermissionExist from '../../../mixins/checkPermissionExist';
import Swal from 'sweetalert2';
import axios from 'axios';
export default {
    props: ['data', 'showHeader', 'user', 'Loading'],

    mixins: [checkPermissionExist],

    data: () => ({
        downloadLoading: false,
        filename: '',
        autoWidth: true,
        bookType: 'xlsx',
        isLoading: false,
    }),

    computed: {},

    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        async clearPendingWorksheet(transaction) {
            try {
                this.isLoading = true;
                console.log('Clear this pending worksheet', transaction);
                const response = await axios.post('/clear_pending_worksheet', {
                    pending_worksheet_id: transaction.id,
                });

                if (response) {
                    this.$emit('clearPendingWorksheet', transaction);
                    Swal.fire({
                        type: 'success',
                        title: 'Clear Pending Worksheet',
                        text: 'Worksheet cleared successfully1!!!',
                        reverseButtons: true,
                        confirmButtonText: 'Ok',
                    });
                }
            } catch (error) {
                console.error('erroor', error);
            } finally {
                this.isLoading = false;
            }
        },

        getFileName() {
            return (
                this.$route.params.reportOn +
                '-' +
                this.$route.params.from +
                '-' +
                this.$route.params.to
            );
        },

        handleDownload() {
            this.downloadLoading = true;
            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = [
                    'Staff',
                    'Transactions',
                    'Amount',
                    'Commission',
                    'Total',
                ];
                const filterVal = [
                    'staff',
                    'transactions',
                    'amount',
                    'commission',
                    'total',
                ];
                const list = this.data;
                console.log('LIST', list);
                const data = this.formatJson(filterVal, list);
                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: this.getFileName(),
                    autoWidth: this.autoWidth,
                    bookType: this.bookType,
                });
                this.downloadLoading = false;
            });
        },

        formatJson(filterVal, jsonData) {
            return Object.keys(jsonData).map(v => {
                console.log();
                return filterVal.map(j => {
                    console.log('J', j);
                    console.log();

                    if (j === 'staff') {
                        return v;
                    } else if (j === 'total') {
                        return (
                            Number(jsonData[v]['amount']) +
                            Number(jsonData[v]['commission'])
                        );
                    } else {
                        return jsonData[v][j];
                    }
                });
            });
        },
    },
};
</script>
